<template>
  <div>
    <div class="conTent">
      <el-tabs  v-model="activeName" @tab-click="tabClickN" class="text_table">
        <el-tab-pane
          label="营养知识"
          name="1"
          v-if="!$store.getters.permissionsName('营养知识')"
        ></el-tab-pane>
        <el-tab-pane
          label="营养品"
          name="2"
          v-if="!$store.getters.permissionsName('营养品')"
        ></el-tab-pane>
      </el-tabs>
      <div class="con_F">
        <span v-show="fan" @click="fanH" class="con_F con_B">
          <i class="iconfont iconfanhui"></i>
          返回
        </span>
      </div>
      <span class="con_F1"></span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
      flag: true,
    };
  },
  data() {
    return {
      fan: false,
      activeName: "0",
      isRouterAlive: true, //进行局部页面刷新
    };
  },

  watch: {
    // 返回显示隐藏
    $route(a) {
      // if (this.$route.query.type === undefined) {
      //   this.$router.push({
      //     path: "/nutritionManagement/managementSon/TrainingNutrition",
      //     query: { type: 1 },
      //   });
      //   this.tabClickN();
      // }

      if (this.$route.query.sp == 1) {
        this.fan = true;
      } else {
        this.fan = false;
      }
    },
  },
  mounted() {
    console.log(this.$route.query.type);
    // console.log(this.$store.getters.permissionsName('营养知识'));

    // 路由判定
    if (this.$route.query.type) {
      this.activeName = this.$route.query.type;
    } else {
      setTimeout(() => {
        if (!this.$store.getters.permissionsName("营养知识")) {
          this.activeName = "1";
        } else if (!this.$store.getters.permissionsName("营养品")) {
          this.activeName = "2";
        }
        this.tabClickN();
      }, 200);
    }
    if (this.$route.query.sp == 1) {
      this.fan = true;
    } else {
      this.fan = false;
    }
  },
  methods: {
    tabClickN() {
      if (this.activeName == "0") {
        let time = setInterval(() => {
          if (!this.$store.getters.permissionsName("营养知识")) {
            this.activeName = "1";
            clearInterval(time);
            this.create();
          } else if (!this.$store.getters.permissionsName("营养品")) {
            this.activeName = "2";
            clearInterval(time);
            this.create();
          }
        }, 200);
      } else {
        this.create();
      }
    },
    fanH() {
      this.$router.go(-1);
      // $router.push($router.push({path: `/nutritionManagement/managementSon/TrainingNutrition?currentPage=${this.$route.query.currentPage}`}))
      // console.log(this.$route.query.currentPage);
    },
    create() {
      //    if(this.$route.query.currentPage) {
      //   this.currentPage = Number(this.$route.query.currentPage)
      // }
      if (this.activeName == "1") {
        this.$router.push({
          path: "/nutritionManagement/managementSon/TrainingNutrition",
          query: { type: 1 },
        });
      } else if (this.activeName == "2") {
        this.$router.push({
          path: "/nutritionManagement/nourishmentSon/nourishmentList",
          query: { type: 2 },
        });
      }
    },
    // 局部刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .text_table {
  width: 90%;
  position: relative;
  // background-color: aquamarine;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  .el-tabs__header {
    margin-bottom: 0;
    .el-tabs__nav-wrap::after {
      background: #fff;
      margin-left: 10px;
      width: 90%;
    }
    .el-tabs__nav {
      height: 55px;
      line-height: 55px;
      margin-left: 50px;
      font-size: 14px;
    }
    .el-tabs__item {
      font-size: 16px;
      // padding: 0 19px;
      &:hover {
        color: #0055e9;
      }
    }
    .is-active {
      color: #0055e9;
    }
    .el-tabs__active-bar {
      background-color: #0055e9;
    }
  }
}
.conTent {
  // background-color: aquamarine;
  display: flex;
  justify-content: space-between;
  .con_F {
    width: 10%;
    height: 55px;
    text-align: right;
    background-color: #fff;
    line-height: 46px;
  }
  .con_B{
     cursor: pointer;
  }
  .con_F1 {
    width: 3%;
    height: 55px;
    text-align: right;
    background-color: #fff;
    line-height: 46px;
  }
}
//  /deep/.el-tabs__active-bar {
//    max-width: 57px !important;
//  }
 /deep/ .el-tabs__nav{
   margin-left: 33px;
 }
</style>